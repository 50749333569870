import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable({
    providedIn: 'root'
})
export class MainInterceptorService implements HttpInterceptor {
    counter: number = 0;
    constructor(private spinnerService: NgxSpinnerService,) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const request = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${localStorage.getItem('UT')}`)
        });

        this.spinnerService.show(); this.counter++;
        setTimeout(() => {

            if (req.method === 'GET') this.spinnerService.hide();

        }, 6000);
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {


                    this.counter--;

                    if (!this.counter || this.counter < 0) {
                        this.spinnerService.hide();
                    }

                }
                return event;
            }),
            catchError((error) => {
                console.log(error);
                this.counter--;
                  if (!this.counter || this.counter < 0) {
                        this.spinnerService.hide();
                    }

                if (!this.counter) {

                    if (error.status == 401 || error.status == 403) {
                        localStorage.clear();
                        location.reload()
                    }
                    else if (error.status == 400) {
                        var arabic = /[\u0600-\u06FF]/;
                        var string = error?.error?.message; // some Arabic string from Wikipedia

                        let isArabic = arabic.test(string); // displays true
                        let msg = '';
                        if (typeof error?.error?.message === 'string') {
                            msg = this.checkErrorsList(error?.error?.message);
                        }
                        else {
                            error?.error?.message?.forEach((err) => {
                                msg += this.checkErrorsList(err);
                            });
                        }
                        if (isArabic == true) {
                            Swal.fire(error?.error?.message, msg, 'error');
                        } else {
                            Swal.fire('الرجاء التثبت من المعطيات', msg, 'error');
                        }



                    }

                    else if (error.status == 409) {
                        var arabic = /[\u0600-\u06FF]/;
                        var string = error?.error?.message; // some Arabic string from Wikipedia

                        let isArabic = arabic.test(string); // displays true
                        let msg = '';
                        if (isArabic == true) {
                            Swal.fire(error?.error?.message, msg, 'error');
                        } else {
                            Swal.fire('الرجاء التثبت من المعطيات', msg, 'error');
                        }
                    }

                    else if (error.status == 406) {
                        var arabic = /[\u0600-\u06FF]/;
                        var string = error?.error?.message; // some Arabic string from Wikipedia

                        let isArabic = arabic.test(string); // displays true
                        let msg = '';
                        if (isArabic == true) {
                            Swal.fire(error?.error?.message, msg, 'error');
                        } else {
                            Swal.fire('الرجاء التثبت من المعطيات', msg, 'error');
                        }
                    }

                    else if (error.status == 404) {
                        var arabic = /[\u0600-\u06FF]/;
                        var string = error?.error?.message; // some Arabic string from Wikipedia

                        let isArabic = arabic.test(string); // displays true
                        let msg = '';
                        if (isArabic == true) {
                            Swal.fire(error?.error?.message, msg, 'error');
                        } else {
                            Swal.fire('الرجاء التثبت من المعطيات', msg, 'error');
                        }
                    }


                    else {
                        Swal.fire('عذرا لقد حصل خطأ', '', 'error');
                    }
                    return throwError('Error while fetching data');
                }
            }));
    }


    checkErrorsList(message: string) {
        let msg = '';
        if (message?.includes('phone')) {
            msg += ` رقم الهاتف`
        }
        if (message?.includes('email')) {
            msg += ` الإيميل`
        }
        if (message?.includes('postal')) {
            msg += ' الترقيم البريدي'
        }
        return msg;
    }
}
