<div
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async"
  class="sidenav flex flex-col"
>
  <div class="sidenav-toolbar flex-none flex items-center">
    <img [src]="imageUrl$ | async" class="w-16 select-none flex-none" />
    <h2 class="title ltr:pl-4 rtl:pr-4 select-none flex-auto">
      {{ title$ | async }}
    </h2>
    <button
      (click)="toggleCollapse()"
      *ngIf="showCollapsePin$ | async"
      class="w-8 h-8 -mr-2 leading-none flex-none hidden lg:block"
      mat-icon-button
      type="button"
    >
      <mat-icon *ngIf="!collapsed" [icIcon]="icMenu" size="32px"></mat-icon>
      <mat-icon
        *ngIf="collapsed"
        [icIcon]="icRadioButtonChecked"
        size="16px"
      ></mat-icon>
    </button>
  </div>

  <vex-scrollbar class="flex-auto">
    <div class="sidenav-items">
      <ng-container *ngFor="let item of items; trackBy: trackByRoute">
        <vex-sidenav-item
          *ngIf="permissions.includes(item?.name) || !item?.name"
          [item]="item"
          [level]="0"
        ></vex-sidenav-item>
      </ng-container>
    </div>
  </vex-scrollbar>
</div>
