import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CustomLayoutComponent } from "./custom-layout/custom-layout.component";
import { PrivateGuard } from "./shared/guards/private.guard";
import { AuthGuard } from "./shared/guards/auth.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "authentication",
    pathMatch: "full",
  },
  {
    path: "app",
    component: CustomLayoutComponent,
    canActivate: [PrivateGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "users-management",
        loadChildren: () =>
          import("./users management/users-management.module").then(
            (m) => m.UsersManagementModule
          ),
      },
      {
        path: "subscriptions",
        loadChildren: () =>
          import("./subscriptions/subscriptions.module").then(
            (m) => m.SubscriptionsModule
          ),
      },
      {
        path: "terms-and-conditions",
        loadChildren: () =>
          import("./termsAndConditions/terms-and-conditions.module").then(
            (m) => m.TermsAndConditionsModule
          ),
      },
      {
        path: "ads",
        loadChildren: () =>
          import("./advertisements/advertisements.module").then(
            (m) => m.AdvertisementsModule
          ),
      },
      {
        path: "funding",
        loadChildren: () =>
          import("./funding/funding.module").then((m) => m.FundingModule),
      },
      {
        path: "account-info",
        loadChildren: () =>
          import("./account-info/account-info.module").then(
            (m) => m.AccountInfoModule
          ),
      },
      {
        path: "admin-management",
        loadChildren: () =>
          import("./admin-management/admin-management.module").then(
            (m) => m.AdminManagementModule
          ),
      },
      {
        path: "transactions-management",
        loadChildren: () =>
          import("./transaction-management/transaction-management.module").then(
            (m) => m.TransactionManagementModule
          ),
      },
      {
        path: "transport-reports",
        loadChildren: () =>
          import("./transport-reports/transport-reports.module").then(
            (m) => m.TransportReportsModule
          ),
      },
      {
        path: "products-management",
        loadChildren: () =>
          import("./product-management/product-management.module").then(
            (m) => m.ProductManagementModule
          ),
      },
      {
        path: "payout-management",
        loadChildren: () =>
          import("./payout-management/payout-management.module").then(
            (m) => m.PayoutManagementModule
          ),
      },
      {
        path: "claims-management",
        loadChildren: () =>
          import("./money-transfer-demand/money-transfer-demand.module").then(
            (m) => m.MoneyTransferDemandModule
          ),
      },
      {
        path: "complaints",
        loadChildren: () =>
          import("./complaints/complaints.module").then(
            (m) => m.ComplaintsModule
          ),
      },
      {
        path: "profits",
        loadChildren: () =>
          import("./profits/profits.module").then((m) => m.ProfitsModule),
      },
      {
        path: "penalties",
        loadChildren: () =>
          import("./penalties/penalties.module").then((m) => m.PenaltiesModule),
      },
      {
        path: "settings",
        loadChildren: () =>
          import("./settings/settings.module").then((m) => m.SettingsModule),
      },
      {
        path: "subscrption",
        loadChildren: () =>
          import("./subscription/subscription.module").then(
            (m) => m.SubscriptionModule
          ),
      },
      {
        path: "bank-accounts",
        loadChildren: () =>
          import("./bank-accounts/bank-accounts.module").then(
            (m) => m.BankAccountsModule
          ),
      },
      {
        path: "transport-cars",
        loadChildren: () =>
          import("./car-management/car-management.module").then(
            (m) => m.CarManagementModule
          ),
      },
      {
        path: "messages-list",
        loadChildren: () =>
          import("./messages/messages.module").then((m) => m.MessagesModule),
      },
      {
        path: "public-message-list",
        loadChildren: () =>
          import("./public-messages/public-messages.module").then(
            (m) => m.PublicMessagesModule
          ),
      },
      {
        path: "blog",
        loadChildren: () =>
          import("./blog-management/blog-management.module").then(
            (m) => m.BlogManagementModule
          ),
      },
      {
        path: "deposits",
        loadChildren: () =>
          import("./deposits/deposits.module").then((m) => m.DepositsModule),
      },
      {
        path: "financial-monitoring",
        loadChildren: () =>
          import("./financial-monitoring/financial-monitoring.module").then(
            (m) => m.FinancialMonitoringModule
          ),
      },
      {
        path: "reservation-management",
        loadChildren: () =>
          import("./reservation-management/reservation-management.module").then(
            (m) => m.ReservationManagementModule
          ),
      },
      {
        path: "transport-management",
        loadChildren: () =>
          import("./transport-management/transport-management.module").then(
            (m) => m.TransportManagementModule
          ),
      },
      {
        path: "contact-demand-list",
        loadChildren: () =>
          import("./contact-demand/contact-demand.module").then(
            (m) => m.ContactDemandModule
          ),
      },
    ],
  },
  {
    path: "authentication",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: "**",
    redirectTo: "authentication",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "corrected",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
