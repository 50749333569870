// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseURL: 'https://nestapp-dev-kharaaj.azurewebsites.net',
  blobURL: 'https://kharaaj.blob.core.windows.net/khraaj',
  firebase: {
    apiKey: "AIzaSyCMnZmp996wuhJC59QczD1cZeciQPFld-U",
    authDomain: "boursa-5bf6c.firebaseapp.com",
    projectId: "boursa-5bf6c",
    storageBucket: "boursa-5bf6c.appspot.com",
    messagingSenderId: "401419524184",
    appId: "1:401419524184:web:e700dc776aa4f3f708922d",
    measurementId: "G-W1GDY1FVX5"
    } 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
