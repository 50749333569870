import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../@vex/services/layout.service';
import { filter, map, startWith } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '../../@vex/utils/check-router-childs-data';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ConfigService } from '../../@vex/services/config.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SidebarComponent } from '../../@vex/components/sidebar/sidebar.component';
import { SseService } from '../shared/services/sse.service';
import { NotificationService } from '../shared/services/notification.service';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ENDPOINTS } from 'src/core/endpoints';
import { DataService } from '../shared/services/data.service';
import { NotificationManagementService } from 'src/@vex/layout/toolbar/toolbar-notifications/services/notification-management.service';
import { NavigationService } from 'src/@vex/services/navigation.service';
@UntilDestroy()
@Component({
  selector: 'vex-custom-layout',
  templateUrl: './custom-layout.component.html',
  styleUrls: ['./custom-layout.component.scss']
})
export class CustomLayoutComponent implements OnInit {

  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
  isDesktop$ = this.layoutService.isDesktop$;

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  );

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;
  notifications: any[];
  unSeenCount: number;
  permissions: any[];

  constructor(private layoutService: LayoutService,
    private configService: ConfigService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private notifyService: NotificationService,
    private sse: SseService,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private _snackBar: MatSnackBar,
    private dataService: DataService,
    private notifService: NotificationManagementService,
    private navService: NavigationService,) {
    this.permissions = this.navService.permissions;
  }


  openSnackBar(type, data) {
    switch (type) {
      case 'userRegister':
        if (this.permissions && (this.permissions.includes(Permissions.VIEW_BUYER) ||
          this.permissions.includes(Permissions.VIEW_SELLER)
          || this.permissions.includes(Permissions.VIEW_BUYER)
          || this.permissions.includes(Permissions.VIEW_BUYER)
        ))
          this._snackBar.open('حساب جديد يحتاج لمعاينة', '', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 2000,
            panelClass: ['blue-snackbar']
          });

        break;

      case 'supportMessage':
        if (this.permissions && (this.permissions.includes(Permissions.VIEW_MESSAGE)))
          this._snackBar.open('رسالة جديدة تحتاج المعاينة', '', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 2000,
            panelClass: ['blue-snackbar']
          });

        break;

      case 'fundTransfer':
        if (this.permissions && (this.permissions.includes(Permissions.VIEW_FUND_TRANSFER))) {
          if(data.fundTransfer)  this._snackBar.open('طلب تحويل ' + data.fundTransfer.amount + ' ريال من ' + data.fundTransfer?.firstName + ' ' + data.fundTransfer?.lastName, '', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 2000,
            panelClass: ['green-snackbar']
          });
          if(data.deposit)  this._snackBar.open(' شحن رصيد بقيمة ' + data.deposit.amount + ' ريال  ' , '', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 2000,
            panelClass: ['green-snackbar']
          });
        }
       

        break;

      case 'fundLoan':
        if (this.permissions && (this.permissions.includes(Permissions.VIEW_FUND_LOAN)))
        this._snackBar.open('طلب قرض تمويل جديد', '', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 2000,
          panelClass: ['blue-snackbar']
        });

        break;

      case 'reportCharge':
        if (this.permissions && (this.permissions.includes(Permissions.VIEW_REPORT)))
        this._snackBar.open('شكاية جديدة تحتاج لمعاينة', '', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 2000,
          panelClass: ['blue-snackbar']
        });

        break;

      case 'reportController':
        if (this.permissions && (this.permissions.includes(Permissions.VIEW_REPORT)))
        this._snackBar.open(data.content, '', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 2000,
          panelClass: ['blue-snackbar']
        });

        break;

      case 'ibanChange':
        if (this.permissions && (this.permissions.includes(Permissions.VIEW_IBAN_CHANGE_DEMAND)))
        this._snackBar.open(data.content, '', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 2000,
          panelClass: ['blue-snackbar']
        });

        break;

      case 'receiveMoney':
        if (this.permissions && (this.permissions.includes(Permissions.VIEW_FUND_TRANSFER)))
        this._snackBar.open(data.content, '', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 2000,
          panelClass: ['blue-snackbar']
        });

        break;

      case 'reportTransporter':
        if (this.permissions && (this.permissions.includes(Permissions.VIEW_REPORT)))
        this._snackBar.open(data.content, '', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 2000,
          panelClass: ['blue-snackbar']
        });

        break;


      default:
        break;
    }

  }

  ngOnInit() {
    this.layoutService.configpanelOpen$.pipe(
      untilDestroyed(this)
    ).subscribe(open => open ? this.configpanel.open() : this.configpanel.close());
    let i = 0;
    let previous = null
    this.sse.returnAsObservable().subscribe(data => {
      this.getNotifications(50)
      if (i == 0) {
        i++;
      } else {
        console.log(data)
        if (data[0] != previous) {
          this.openSnackBar(data[0].type, data[0])
          console.log(data[0]);
          previous = data[0]
        }


      }
    }
    );
    this.sse.GetExchangeData(ENDPOINTS.NOTIFICATION.FUND_TRANSFER);
  }

  getNotifications(limit) {
    this.notifService.getNotifications(limit).subscribe(
      res => {
        this.dataService.update(res.data)
      }
    )
  }








}


export enum Permissions {
  //* Seller permissions
  VIEW_SELLER = 'VIEW-SELLER',
  EXPORT_SELLER = 'EXPORT-SELLER',
  UPDATE_SELLER = 'UPDATE-SELLER',
  APPROVE_SELLER = 'APPROVE-SELLER',
  REJECT_SELLER = 'REJECT-SELLER',

  //* Buyer permissions
  VIEW_BUYER = 'VIEW-BUYER',
  EXPORT_BUYER = 'EXPORT-BUYER',
  UPDATE_BUYER = 'UPDATE-BUYER',
  APPROVE_BUYER = 'APPROVE-BUYER',
  REJECT_BUYER = 'REJECT-BUYER',

  //* Buyer Seller permissions
  VIEW_BUYER_SELLER = 'VIEW-BUYER-SELLER',
  EXPORT_BUYER_SELLER = 'EXPORT-BUYER-SELLER',
  UPDATE_BUYER_SELLER = 'UPDATE-BUYER-SELLER',
  APPROVE_BUYER_SELLER = 'APPROVE-BUYER-SELLER',
  REJECT_BUYER_SELLER = 'REJECT-BUYER-SELLER',

  //* Transporter permissions
  VIEW_TRANSPORTER = 'VIEW-TRANSPORTER',
  EXPORT_TRANSPORTER = 'EXPORT-TRANSPORTER',
  UPDATE_TRANSPORTER = 'UPDATE-TRANSPORTER',
  APPROVE_TRANSPORTER = 'APPROVE-TRANSPORTER',
  REJECT_TRANSPORTER = 'REJECT-TRANSPORTER',

  //* Controller permissions
  VIEW_CONTROLLER = 'VIEW-CONTROLLER',
  EXPORT_CONTROLLER = 'EXPORT-CONTROLLER',
  CREATE_CONTROLLER = 'CREATE-CONTROLLER',
  UPDATE_CONTROLLER = 'UPDATE-CONTROLLER',
  APPROVE_CONTROLLER = 'APPROVE-CONTROLLER',

  //* Marketer permissions
  VIEW_MARKETER = 'VIEW-MARKETER',
  EXPORT_MARKETER = 'EXPORT-MARKETER',
  CREATE_MARKETER = 'CREATE-MARKETER',
  UPDATE_MARKETER = 'UPDATE-MARKETER',
  APPROVE_MARKETER = 'APPROVE-MARKETER',

  //* Admins permissions
  VIEW_ADMIN = 'VIEW-ADMIN',
  CREATE_ADMIN = 'CREATE-ADMIN',
  UPDATE_ADMIN = 'UPDATE-ADMIN',

  //* Fund Transfers permissions
  VIEW_FUND_TRANSFER = 'VIEW-FUND-TRANSFER',
  UPDATE_FUND_TRANSFER_STATUS = 'UPDATE-FUND-TRANSFER-STATUS',

  //* Fund Loans permissions
  VIEW_FUND_LOAN = 'VIEW-FUND-LOAN',
  UPDATE_FUND_LOAN_STATUS = 'UPDATE-FUND-LOAN-STATUS',

  //* IBAN Change Demand
  VIEW_IBAN_CHANGE_DEMAND = 'VIEW-IBAN-CHANGE-DEMAND',
  UPDATE_IBAN_CHANGE_DEMAND_STATUS = 'UPDATE-IBAN-CHANGE-DEMAND-STATUS',

  //* Settings permissions
  VIEW_CAR_TYPE = 'VIEW-CAR-TYPE',
  CREATE_CAR_TYPE = 'CREATE-CAR-TYPE',
  DELETE_CAR_TYPE = 'DELETE-CAR-TYPE',
  VIEW_PRODUCT_PACKAGE = 'VIEW-PRODUCT-PACKAGE',
  CREATE_PRODUCT_PACKAGE = 'CREATE-PRODUCT-PACKAGE',
  DELETE_PRODUCT_PACKAGE = 'DELETE-PRODUCT-PACKAGE',
  VIEW_PRODUCT_UNIT = 'VIEW-PRODUCT-UNIT',
  CREATE_PRODUCT_UNIT = 'CREATE-PRODUCT-UNIT',
  DELETE_PRODUCT_UNIT = 'DELETE-PRODUCT-UNIT',
  UPDATE_MARKET_TIME = 'UPDATE-MARKET-TIME',
  VIEW_POINT_VALUE = 'VIEW-POINT-VALUE',
  UPDATE_POINT_VALUE = 'UPDATE-POINT-VALUE',
  VIEW_VAT = 'VIEW-VAT',
  UPDATE_VAT = 'UPDATE-VAT',
  VIEW_CATEGORY = 'VIEW-CATEGORY',
  CREATE_CATEGORY = 'CREATE-CATEGORY',
  DELETE_CATEGORY = 'DELETE-CATEGORY',
  UPDATE_PROFIT = 'UPDATE-PROFIT',
  VIEW_PROFIT = 'VIEW-PROFIT',
  CREATE_REGION = 'CREATE-REGION',
  DELETE_REGION = 'DELETE-REGION',
  CREATE_QUALITY = 'CREATE-QUALITY',
  VIEW_QUALITY = 'VIEW-QUALITY',
  DELETE_QUALITY = 'DELETE-QUALITY',
  VIEW_TYPE = 'VIEW-TYPE',
  CREATE_TYPE = 'CREATE-TYPE',
  DELETE_TYPE = 'DELETE-TYPE',
  VIEW_SETTING = 'VIEW-SETTING',
  UPDATE_SETTING = 'UPDATE-SETTING',
  VIEW_COOLING_TYPE = 'VIEW-COOLING-TYPE',
  CREATE_COOLING_TYPE = 'CREATE-COOLING-TYPE',
  DELETE_COOLING_TYPE = 'DELETE-COOLING-TYPE',

  //* Complaints permissions
  VIEW_COMPLAINT = 'VIEW-COMPLAINT',
  UPDATE_COMPLAINT_STATUS = 'UPDATE-COMPLAINT-STATUS',

  //* Reports permissions
  VIEW_REPORT = 'VIEW-REPORT',
  UPDATE_REPORT_STATUS = 'UPDATE-REPORT-STATUS',

  //* Forsa Programs permissions
  VIEW_FORSA_PROGRAM = 'VIEW-FORSA-PROGRAM',
  VIEW_SUBSCRIPTION = 'VIEW-SUBSCRIPTION',
  UPDATE_FORSA_PROGRAM = 'UPDATE-FORSA-PROGRAM',

  //* Rules permissions
  VIEW_RULES = 'VIEW-RULES',
  UPDATE_RULES_CONTENT = 'UPDATE-RULES-CONTENT',

  //* Platform Revenue permissions
  VIEW_PLATFORM_REVENUE = 'VIEW-PLATFORM-REVENUE',
  EXPORT_PLATFORM_REVENUE = 'EXPORT-PLATFORM-REVENUE',

  //*Product permissions
  VIEW_PRODUCT = 'VIEW-PRODUCT',
  UPDATE_PRODUCT = 'UPDATE-PRODUCT',

  //*Customer support permission
  VIEW_JOIN_REQUEST = 'VIEW-JOIN-REQUEST',
  VIEW_MESSAGE = 'VIEW-MESSAGE',

  //*Blog permissions
  MANAGE_BLOG = 'MANAGE-BLOG',

  //*User management permissions
  UPDATE_USER_STATE = "UPDATE-USER-STATE"
}
