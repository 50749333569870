export const ENDPOINTS = {
  SIGN_IN: '/api/v1/backoffice/auth/singin',
  BUYERS_SELLERS_LIST: '/api/v1/backoffice/buyers-sellers',
  PATCH_BUYERS_SELLERS_BALANCE: '/api/v1/backoffice/buyers-sellers/balance/',
  PATCH_BUYERS_SELLERS_TRUST: '/api/v1/backoffice/buyers-sellers/trust/',
  EXPORT_PDF_BUYER_SELLER: '/api/v1/backoffice/buyers-sellers/export-pdf',
  EXPORT_EXCEL_BUYER_SELLER: '/api/v1/backoffice/buyers-sellers/export-excel',
  GET_LOGS_BUYER_SELLER: '/api/v1/backoffice/buyers-sellers',
  BUYERS_SELLERS_STAT: '/api/v1/backoffice/buyers-sellers/statistics',
  BUYERS_SELLERS_UPDATE: '/api/v1/backoffice/buyers-sellers/',
  TRANSFER_REQUEST_LIST: '/api/v1/backoffice/fund-transfers',
  PENDING_TRANSFER_REQUEST_LIST: '/api/v1/backoffice/fund-transfers/pending',
  WAITING_REQUEST_LIST: '/api/v1/backoffice/fund-transfers/waiting',
  APPROVED_TRANSFER_REQUEST_LIST: '/api/v1/backoffice/fund-transfers/approved',
  REFUSED_TRANSFER_REQUEST_LIST: '/api/v1/backoffice/fund-transfers/refused',
  TRANSFER_REQUEST_STATS: '/api/v1/backoffice/fund-transfers/stats',
  TRANSFER_REQUEST_UPDATE: '/api/v1/backoffice/fund-transfers/',
  GET_CAR_TYPES: '/api/v1/transports/cars/types',
  GET_TRANSPORTS: '/api/v1/backoffice/transports',
  ADD_CAR_TYPE: '/api/v1/backoffice/transports/cars/types',
  DELETE_CAR_TYPE: '/api/v1/backoffice/transports/cars/types/',
  GET_UNIT_TYPES: '/api/v1/products/units',
  ADD_UNIT_TYPE: '/api/v1/backoffice/products/units',
  DELETE_UNIT_TYPE: '/api/v1/backoffice/products/units/',
  GET_WRAPPING_TYPES: '/api/v1/products/packages',
  ADD_WRAPPING_TYPE: '/api/v1/backoffice/products/packages',
  DELETE_WRAPPING_TYPE: '/api/v1/backoffice/products/packages/',
  GET_TRADE_TIMES: '/api/v1/market/times',
  UPDATE_TRADE_TIME: '/api/v1/backoffice/market/times/',
  STATISTICS_PER_YEAR: '/api/v1/backoffice/transactions/statistics-per-year',
  LATEST_TRANSACTIONS: '/api/v1/backoffice/transactions/last/users?limit=100',
  LATEST_PENDING_ACTIVITIES: '/api/v1/backoffice/balance-activities/pending?limit=100',
  LATEST_balance_ACTIVITIES: '/api/v1/backoffice/balance-activities/available?limit=100',
  UPDATE_TERMS: '/api/v1/backoffice/rules',
  PENDING_BALANCE_TOTAL: '/api/v1/backoffice/balance-activities/pending/total',
  PAYOUT_TOTAL: '/api/v1/backoffice-payout-demand/stats/total',
  DEPOSIT_TOTAL: '/api/v1/backoffice/deposits/total',
  GET_DEPOSITS: '/api/v1/backoffice/deposits',
  GET_FUND_TRANSFER_TOTAL: '/api/v1/backoffice/fund-transfers/stats/total',
  BANK_ACCOUNT_TOTAL: '/api/v1/backoffice/accounts/total',
  GET_TERMS: '/api/v1/rules',
  USER_LIST: '/api/v1/backoffice/users',
  USER_COUNT_STATS: '/api/v1/backoffice/users/count',
  TRANSPORTERS_COUNT_STATS: '/api/v1/backoffice/users/count',
  MARKETERS_COUNT_STATS: '/api/v1/backoffice/users/count',
  CATEGORIES_STATS: '/api/v1/backoffice/categories/statistics-per-year?limit=7',
  PATCH_REPORT: '/api/v1/backoffice/reports/',
  POINT_VALUE: '/api/v1/backoffice/point-value',
  VAT: '/api/v1/backoffice/vats',
  PROFIT: '/api/v1/backoffice/profits',
  GENERAL_SETTINGS: '/api/v1/backoffice/settings',
  GET_PURCHASES: '/api/v1/backoffice/buyers-sellers​/',
  GET_SALES: '/api/v1/backoffice/buyers-sellers​/',
  TRANSACTIONS: '/api/v1/backoffice/transactions',
  DEPOSITS: '/api/v1/backoffice/deposits',
  BALANCE_ACTIVITIES: '/api/v1/backoffice/balance-activities',
  ACTIVITY_LOGS: '/api/v1/backoffice/activity-logs',


  RECEIVE_MONEY: {
    PENDING: '/api/v1/backoffice-payout-demand/pending',
    APPROVED: '/api/v1/backoffice-payout-demand/approved',
    REFUSED: '/api/v1/backoffice-payout-demand/refused',
    UPDATE_BY_ID: '/api/v1/backoffice-payout-demand/',
    GET_BY_ID: '/api/v1/backoffice-payout-demand/',
    STATS: '/api/v1/backoffice-payout-demand/stats'
  },
  ARTICLE: {
    GETALL: '/api/v1/backoffice/articles',
    POST: '/api/v1/backoffice/articles',
    PUBLISH: '/api/v1/backoffice/articles/publish/',
    UNPUBLISH: '/api/v1/backoffice/articles/unPublish/'


  },

  BUYER: {
    GET_ALL: '/api/v1/backoffice/buyers',
    PATCH: '/api/v1/backoffice/buyers/',
    PATCH_BALANCE: '/api/v1/backoffice/buyers/balance/',
    GET_STATISTICS: '/api/v1/backoffice/buyers/statistics',
    GET_PURCHASES: '/api/v1/backoffice/buyers/',
    EXPORT_PDF: '/api/v1/backoffice/buyers/export-pdf',
    EXPORT_EXCEL: '/api/v1/backoffice/buyers/export-excel',
    GET_LOGS_BUYER: '/api/v1/backoffice/buyers/logs',
  },
  SELLER: {
    GET_SELLERS: '/api/v1/backoffice/sellers',
    PATCH: '/api/v1/backoffice/sellers/',
    PATCH_BALANCE: '/api/v1/backoffice/sellers/balance/',
    PATCH_TRUST: '/api/v1/backoffice/sellers/trust/',
    GET_STATISTICS: '/api/v1/backoffice/sellers/statistics',
    GET_SALES: '/api/v1/backoffice/sellers/',
    EXPORT_PDF: '/api/v1/backoffice/sellers/export-pdf',
    EXPORT_EXCEL: '/api/v1/backoffice/sellers/export-excel',
    GET_LOGS_SELLER: '/api/v1/backoffice/sellers/logs',
  },
  LOANS: {
    GET_LOANS: '/api/v1/backoffice/fund-loans',
    CREATE_LOANS: '/api/v1/backoffice/fund-loans',
  },
  TRANSPORTERS: {
    GET_TRANSPORTERS_AGENTS: '/api/v1/backoffice/transporter-agent',
    GET_TRANSPORTERS_CARS: '/api/v1/backoffice/transporter-agent/cars',
    GET_TRANSPORTERS_COMPANY: '/api/v1/backoffice/transporter-company',
    GET_STATS_COMPANY: '/api/v1/backoffice/transporter-company/statistics',
    GET_STATS_AGENTS: '/api/v1/backoffice/transporter-agent/statistics',
    PATCH_STATS_AGENTS: '/api/v1/backoffice/transporter-agent/',
    PATCH_BALANCE: '/api/v1/backoffice/transporter-agent/balance/',
    PATCH_STATS_COMPNAY: '/api/v1/backoffice/transporter-company/',
    EXPORT_PDF: '',
    EXPORT_EXCEL: '/api/v1/backoffice/transporter-agent/export-excel'
  },
  ADMIN: {
    GET_ADMINS: '/api/v1/backoffice/admins',
    POST_ADMIN: '/api/v1/backoffice/admins',
    PATCH_ADMIN: '/api/v1/backoffice/admins/',
    PATCH_SUPERADMIN: '/api/v1/backoffice/admins/super-admin/',
    GET_ADMIN: '/api/v1/backoffice/admins/',
    GET_MANGAMENTS: '/api/v1/backoffice/management-packs/full',
    EXPORT_PDF: '',
    EXPORT_EXCEL: ''
  },
  CONTROLLERS: {
    GET_CONTROLLERS: '/api/v1/backoffice/controllers',
    POST_CONTROLLER: '/api/v1/backoffice/controllers',
    PATH_CONTROLLER: '/api/v1/backoffice/controllers/',
    GET_STATS: '/api/v1/backoffice/controllers/statistics',
    GET_CONTROLERS_REGION: '/api/v1/backoffice/regions/cities/',
    EXPORT_PDF: '/api/v1/backoffice/controllers/export-pdf',
    EXPORT_EXCEL: '/api/v1/backoffice/controllers/export-excel'
  },
  MARKETER: {
    GET_MARKETERS: '/api/v1/backoffice/marketers',
    POST_MARKETER: '/api/v1/backoffice/marketers',
    PATCH_MARKETER: '/api/v1/backoffice/marketers/',
    GET_STATS: '/api/v1/backoffice/marketers/statistics',
    POST_IMAGE: '/api/v1/backoffice/files/upload',
    EXPORT_PDF: '/api/v1/backoffice/marketers/export-pdf',
    EXPORT_EXCEL: '/api/v1/backoffice/marketers/export-excel'
  },
  FORSA: {
    GET_PROGRAMERS: '/api/v1/backoffice/forsa-programs',
    GET_INSCRIPTION: '/api/v1/backoffice/forsa-programs/inscriptions',
    GET_INSCRI_STATS:
      '/api/v1/backoffice/forsa-programs/inscriptions/statistic',
    UPDATE_STAS: '/api/v1/backoffice/forsa-programs/',
  },
  SUBSCRIPTION: {
    GET_SUBSCRIPTIONS: '/api/v1/backoffice/forsa-programs/inscriptions',
    UPDATE_SUBSCRIPTION: '/api/v1/backoffice/forsa-programs/',
  },
  ACCOUNT: {
    CHANGE_PASSWORD: '/api/v1/backoffice/admin-account/change-password',
  },
  ADS: {
    URL: '/api/v1/advertisements',
  },
  REPORTS: {
    GET_REPORTS: '/api/v1/backoffice/reports',
    GET_REPORTS_DONE: '/api/v1/backoffice/reports/done',
    GET_REPORTS_FINISHED: '/api/v1/backoffice/reports/finished',
    GET_REPORT_BY_ID: '/api/v1/backoffice/reports/',
    GET_REPORTS_STATS: '/api/v1/backoffice/reports/statistic',
    CONFIRM_REPORT: '/api/v1/backoffice/reports/',
    APPROVE_REPORT: '/api/v1/backoffice/reports/',
    REFUSE_REPORT: '/api/v1/backoffice/reports/',
    GET_COMPLAINTS: '/api/v1/backoffice/reports/complaints',

    REJECT_REPORT: '/api/v1/backoffice/reports/',
    EXPORT_PDF: '',
    EXPORT_EXCEL: ''
  },
  TRANSPORTER_REPORTS: {
    GET_BY_ID_REPORTS: '/api/v1/backoffice/transports/report/',
    GET_PENDING_REPORTS: '/api/v1/backoffice/transports/report/pending',
    GET_APPROVED_REPORTS: '/api/v1/backoffice/transports/report/approved',
    GET_REJECTED_REPORTS: '/api/v1/backoffice/transports/report/rejected',
    GET_PENDING_REPORTS_COUNT: '/api/v1/backoffice/transports/report/pending/count',
    GET_APPROVED_REPORTS_COUNT: '/api/v1/backoffice/transports/report/approved/count',
    GET_REJECTED_REPORTS_COUNT: '/api/v1/backoffice/transports/report/rejected/count',
    APPROVE: '/api/v1/backoffice/transports/report/approve/',
    REJECT: '/api/v1/backoffice/transports/report/reject/',
  },
  Regions: {
    GET_REGIONS: '/api/v1/regions',
    GET_CITY: '/api/v1/regions/',
    ADD_REGION: '/api/v1/backoffice/regions',
    ADD_CITY: '/api/v1/backoffice/regions',
    REMOVE_REGION: '/api/v1/backoffice/regions/',
    REMOVE_CITY: '/api/v1/backoffice/regions/cities/'
  },
  QUALITY: {
    GET_QUALITIES: '/api/v1/products/qualities',
    ADD_QUALITY: '/api/v1/backoffice/products/qualities',
    REMOVE_QUALITY: '/api/v1/backoffice/products/qualities/',
  },
  PRODUCT: {
    GET: '/api/v1/backoffice/products/',
    STATS_PENDING: '/api/v1/backoffice/products/stats/pending',
    STATS_APPROVED: '/api/v1/backoffice/products/stats/approved',
    STATS_DISAPPROVED: '/api/v1/backoffice/products/stats/disapproved',
    PENDING: '/api/v1/backoffice/products/pending',
    ALL: '/api/v1/backoffice/products/all',
    APPROVED: '/api/v1/backoffice/products/approved',
    DISAPPROVED: '/api/v1/backoffice/products/disapproved',
    APPROVE: '/api/v1/backoffice/products/approve/',
    DISAPPROVE: '/api/v1/backoffice/products/disapprove/',
  },
  TYPE: {
    GET_TYPES: '/api/v1/products/types',
    ADD_TYPE: '/api/v1/backoffice/products/types',
    REMOVE_TYPE: '/api/v1/backoffice/products/types/',
  },
  BANK_ACCOUNT: {
    UPDATE: '/api/v1/backoffice/platform-bank-account',
    GET: '/api/v1/platform-bank-account'
  },
  MESSAGES: {
    ALL: '/api/v1/support/messages',
    BY_TOPIC: '/api/v1/support/messages/topic/',
  },
  PUBLIC_MESSAGES: {
    ALL: '/api/v1/contact-messages',
  },
  CLAIMS: {
    ALL: '/api/v1/money-transfer-demand',
    APPROVE: '/api/v1/money-transfer-demand/approve/',
    REJECT: '/api/v1/money-transfer-demand/reject/',
  },
  BANK_ACCOUNT_CHANGES: {
    GET_LIST: '/api/v1/backoffice/iban-change-demands',
    EXPORT_PDF: '',
    EXPORT_EXCEL: ''
  },
  NOTIFICATION: {
    REGISTER: '/api/v1/notifications/mobile/registration',
    FUND_TRANSFER: '/api/v1/funds/admin_notif_events',
    ADMIN: '/api/v1/notifications/admin',
    UPDATE_STATUS: '/api/v1/notifications/admin/status/',
    UPDATE_ALL_STATUS: '/api/v1/notifications/admin/status',
  },
  PROFIT_HISTORY: {
    ALL: '/api/v1/profit_history',
    EXPORT_EXCEl: '/api/v1/profit_history/export-excel',
    STATISTICS: '/api/v1/profit_history/statistics',
    TRANSACTION: '/api/v1/profit_history/transaction',
    TRANSPORT: '/api/v1/profit_history/transport',
    FORSA: '/api/v1/profit_history/forsa',
    PENALTY: '/api/v1/profit_history/penalty'

  },
  COOLING: '/api/v1/cooling',
  CATEGORY: {
    CREATE_PARENT: '/api/v1/backoffice/categories',
    CREATE_SUB: '/api/v1/backoffice/categories/sub',
    GET_PARENT_LIST: '/api/v1/backoffice/categories/parents',
    GET_SUBS_LIST: '/api/v1/backoffice/categories/subs',
    DELETE_PARENT: '/api/v1/backoffice/categories/parent',
    DELETE_SUB: '/api/v1/backoffice/categories/sub',
    STATISTICS: '/api/v1/backoffice/categories/sub',
    GETALLSUBLIST: '/api/v1/categories/sub',


  },
  TOPICS: '/api/v1/topics'

};
