import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { VexModule } from "../@vex/vex.module";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { CustomLayoutModule } from "./custom-layout/custom-layout.module";
import { ar_EG, en_US, NZ_I18N } from "ng-zorro-antd/i18n";
import { registerLocaleData } from "@angular/common";
import en from "@angular/common/locales/en";
import { FormsModule } from "@angular/forms";
import { MainInterceptorService } from "./shared/interceptors/main-interceptor.service";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { NgxSpinnerModule } from "ngx-spinner";
import { QuillModule } from "ngx-quill";
import { environment } from "../environments/environment";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireModule } from "@angular/fire";
import { MessagingService } from "./shared/services/messaging.service";
import { ToastrModule } from "ngx-toastr";
import { DataService } from "./shared/services/data.service";
registerLocaleData(en);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgxSpinnerModule,
    // Vex
    VexModule,
    CustomLayoutModule,
    FormsModule,
    SweetAlert2Module.forRoot(),
    ToastrModule.forRoot(),
    QuillModule.forRoot(),
  ],
  providers: [
    { provide: NZ_I18N, useValue: ar_EG },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MainInterceptorService,
      multi: true,
    },
    MessagingService,
    DataService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
