import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';    
    
@Injectable({    
  providedIn: 'root'    
})    
export class EventEmitterService {    
    
  invokeReportComponentFunction = new EventEmitter(); 
  invokeBuyerComponentFunction = new EventEmitter(); 
  invokeSellerComponentFunction = new EventEmitter(); 
  invokeBuyerSellerComponentFunction = new EventEmitter();   
  invokeTransporterComponentFunction = new EventEmitter();  
  invokeBankAccountComponentFunction = new EventEmitter(); 
  invokePayoutComponentFunction = new EventEmitter(); 
  invokeComplaintsComponentFunction = new EventEmitter(); 
  invokeSubscriptionComponentFunction = new EventEmitter();
  invokeFundTransferComponentFunction = new EventEmitter();
  invokeMessageComponentFunction = new EventEmitter();
  invokeProductComponentFunction = new EventEmitter(); 
  invokeReportTransporterComponentFunction = new EventEmitter(); 
  subsMessage: Subscription;   
  subsBuyer: Subscription;   
  subsSeller: Subscription;   
  subsBuyerSeller: Subscription;   
  subsTransporter: Subscription;   
  subsReport: Subscription;   
  subsPayout: Subscription;   
  subsFundTransfer: Subscription;   
  subsSubscription: Subscription;  
  subsBankAccount: Subscription;  
  subsComplaints: Subscription;    
  subsProducts: Subscription;   
  subsReportTransporter: Subscription;    

    
  constructor() { }    
    
  onReportComponentNotificationClick() {    
    this.invokeReportComponentFunction.emit();    
  }

  onReportTransporterComponentNotificationClick() {    
    this.invokeReportTransporterComponentFunction.emit();    
  }

  onProductComponentNotificationClick() {    
    this.invokeReportComponentFunction.emit();    
  }

  onFundTransferComponentNotificationClick() {    
    this.invokeFundTransferComponentFunction.emit();    
  }
  
  onBuyerComponentNotificationClick() {    
    this.invokeBuyerComponentFunction.emit();    
  }

  onSellerComponentNotificationClick() {    
    this.invokeSellerComponentFunction.emit();    
  }

  onBuyerSellerComponentNotificationClick() {    
    this.invokeBuyerSellerComponentFunction.emit();    
  }

  onTransporterComponentNotificationClick() {    
    this.invokeTransporterComponentFunction.emit();    
  }

  onBankAccountComponentNotificationClick() {    
    this.invokeBankAccountComponentFunction.emit();    
  }

  onPayoutComponentNotificationClick() {    
    this.invokePayoutComponentFunction.emit();    
  }

  onComplaintsComponentNotificationClick() {    
    this.invokeComplaintsComponentFunction.emit();    
  }

  onSubscriptionComponentNotificationClick() {    
    this.invokeSubscriptionComponentFunction.emit();    
  }

  onMessageComponentNotificationClick() {    
    this.invokeMessageComponentFunction.emit();    
  }
}    