import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ENDPOINTS} from '../../../core/endpoints';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    constructor(private httpClient: HttpClient) {
    }

    signIn(credentials): Observable<any> {
        return this.httpClient.post(`${environment.baseURL}${ENDPOINTS.SIGN_IN}`, credentials);
    }

    signOut() {
        return new Observable((observer) => {
            localStorage.clear();
            observer.next(true);
        });
    }
}
