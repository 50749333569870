import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { NotificationService } from './notification.service';


@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  constructor(private afMessaging: AngularFireMessaging, private notificationService: NotificationService) { }
  
  //Request permission & get FCM token
  requestPermission() {
    this.afMessaging.requestToken
      .subscribe(
        (token) => { console.log('Permission granted! Save to the server!', token);
        this.notificationService.registerToken(token).subscribe(
          res=>{
            console.log(res)
          },
          err=>{
            console.log(err)
          }
        )

       },
        (error) => { console.error(error); },  
      );
  }

  //Remove FCM token
  deleteToken() {
    this.afMessaging.getToken
      
      .subscribe(
        (token) => { 
          this.afMessaging.deleteToken(token)
          console.log('Token deleted!'); },
      );
  }

  //Listen for coming messages
  listen() {
    this.afMessaging.messages
      .subscribe((message) => { console.log(message); });
  }

  


}
