import {Injectable} from '@angular/core';
import {NavigationDropdown, NavigationItem, NavigationLink, NavigationSubheading} from '../interfaces/navigation-item.interface';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    items: NavigationItem[] = [];

    permissions: string[];
    packs: string[];

    private _openChangeSubject = new Subject<NavigationDropdown>();
    openChange$ = this._openChangeSubject.asObservable();

    constructor() {
    }

    triggerOpenChange(item: NavigationDropdown) {
        this._openChangeSubject.next(item);
    }

    isLink(item: NavigationItem): item is NavigationLink {
        return item.type === 'link';
    }

    isDropdown(item: NavigationItem): item is NavigationDropdown {
        return item.type === 'dropdown';
    }

    isSubheading(item: NavigationItem): item is NavigationSubheading {
        return item.type === 'subheading';
    }

    setNavigationItems(menuItems: NavigationItem[]) {
        this.items = menuItems;
    }

    setPacksAndPermissions(packs) {
        this.packs = [];
        this.permissions = [];
        packs.forEach((pack) => {
            this.packs.push(pack?.name);
            pack?.permissions?.forEach((permission) => {
                this.permissions.push(permission?.slug);
            });
        });
        console.log(this.permissions);
        
    }

    canDoIt(action: string) {
        return this.permissions.includes(action);
    }
}
