import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

export interface NotifInfo {
  allNotifCount: number;
  fundTransferCount: number;
  subscriptionCount: number;
  inscriptionCount: number;
  fundLoanCount: number;
  reportCount: number;
  buyerCount: number;
  sellerCount: number;
  buyerSellerCount: number;
  controllerCount: number;
  marketerCount: number;
  transporterCount: number;
  receiveMoneyCount: number;
  ibanChangeDemandCount: number;
  messageCount: number;
  productCount: number;
  reportTransportCount: number;
  notifications: any[];
}

@Injectable()
export class DataService {
  private _todo = new BehaviorSubject<NotifInfo>({
    allNotifCount: 0,
    fundLoanCount: 0,
    subscriptionCount: 0,
    inscriptionCount: 0,
    fundTransferCount: 0,
    reportCount: 0,
    notifications: [],
    buyerCount: 0,
    sellerCount: 0,
    buyerSellerCount: 0,
    controllerCount: 0,
    marketerCount: 0,
    transporterCount: 0,
    receiveMoneyCount: 0,
    ibanChangeDemandCount: 0,
    messageCount: 0,
    productCount:0,
    reportTransportCount:0
  });
  readonly todos$ = this._todo.asObservable();
  private todo: NotifInfo = {
    allNotifCount: 0,
    fundLoanCount: 0,
    subscriptionCount: 0,
    inscriptionCount: 0,
    fundTransferCount: 0,
    reportCount: 0,
    notifications: [],
    buyerCount: 0,
    sellerCount: 0,
    buyerSellerCount: 0,
    controllerCount: 0,
    marketerCount: 0,
    transporterCount: 0,
    receiveMoneyCount: 0,
    ibanChangeDemandCount: 0,
    messageCount: 0,
    productCount:0,
    reportTransportCount:0
  };
  constructor() {}
  loadAll() {
    this.todo = {
      allNotifCount: 0,
      fundLoanCount: 0,
      subscriptionCount: 0,
      inscriptionCount: 0,
      fundTransferCount: 0,
      reportCount: 0,
      notifications: [],
      buyerCount: 0,
      sellerCount: 0,
      buyerSellerCount: 0,
      controllerCount: 0,
      marketerCount: 0,
      transporterCount: 0,
      receiveMoneyCount: 0,
    ibanChangeDemandCount: 0,
    messageCount: 0,
    productCount:0,
    reportTransportCount: 0
    };
    this._todo.next(this.todo);
  }
  update(data: any[]) {
    this.todo = {
      allNotifCount: 0,
      fundLoanCount: 0,
      subscriptionCount: 0,
      inscriptionCount: 0,
      fundTransferCount: 0,
      reportCount: 0,
      notifications: [],
      buyerCount: 0,
      sellerCount: 0,
      buyerSellerCount: 0,
      controllerCount: 0,
      marketerCount: 0,
      transporterCount: 0,
      receiveMoneyCount: 0,
    ibanChangeDemandCount: 0,
    messageCount: 0,
    productCount:0,
    reportTransportCount: 0
    };

    this.todo.notifications = data;

    data.forEach((item) => {
      if (item.seen == false) {
        this.todo.allNotifCount++;
        if (item.notification.userRegister) {
          this.todo.inscriptionCount++;
          if (item.notification.userRegister.type == "BUYER_SELLER")
            this.todo.buyerSellerCount++;
          if (item.notification.userRegister.type == "BUYER")
            this.todo.buyerCount++;
          if (item.notification.userRegister.type == "SELLER")
            this.todo.sellerCount++;
          if (item.notification.userRegister.type == "TRANSPORTER_AGENT")
            this.todo.transporterCount++;
          if (item.notification.userRegister.type == "TRANSPORTER_COMPANY")
            this.todo.transporterCount++;
          if (item.notification.userRegister.type == "TRANSPORTER")
            this.todo.transporterCount++;
          if (item.notification.userRegister.type == "CONTROLLER")
            this.todo.controllerCount++;
          if (item.notification.userRegister.type == "MARKETER")
            this.todo.marketerCount++;
        }
        if (item.notification.fundTransfer) {
          this.todo.fundTransferCount++;
        }
        if (item.notification.reportTransporter) {
          this.todo.reportTransportCount++;
        }
        if (item.notification.fundLoan) {
          this.todo.fundLoanCount++;
        }
        if (item.notification.report) {
          this.todo.reportCount++;
        }
        if (item.notification.receiveMoney) {
          this.todo.receiveMoneyCount++;
        }
        if (item.notification.ibanChangeDemand) {
          this.todo.ibanChangeDemandCount++;
        }
        if (item.notification.message) {
          this.todo.messageCount++;
        }
        if (item.notification.product) {
          this.todo.productCount++;
        }
      }
    });
    console.log(this.todo);
    this._todo.next(Object.assign([], this.todo));
  }

  create() {
    this._todo.next(
      Object.assign([], {
        allNotifCount: 0,
        productCount:0,
        fundLoanCount: 0,
        subscriptionCount: 0,
        inscriptionCount: 0,
        fundTransferCount: 0,
        reportCount: 0,
        notifications: [],
        buyerCount: 0,
        sellerCount: 0,
        buyerSellerCount: 0,
        controllerCount: 0,
        marketerCount: 0,
        transporterCount: 0,
        receiveMoneyCount: 0,
    ibanChangeDemandCount: 0,
    reportTransportCount:0,
    messageCount: 0,
      })
    );
  }
}
