import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/internal/Observable';
import { ENDPOINTS } from 'src/core/endpoints';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  BASE_URI = environment.baseURL;
  constructor(private http: HttpClient,private toastr: ToastrService) {

   }

   showSuccess(message, title){
    this.toastr.success(message, title)
}

showError(message, title){
    this.toastr.error(message, title)
}

showInfo(message, title){
    this.toastr.info(message, title)
}

showWarning(message, title){
    this.toastr.warning(message, title)
}

   registerToken(token): Observable<any> {
    return this.http.post(
      `${this.BASE_URI}${ENDPOINTS.NOTIFICATION.REGISTER}`,
      {
        token: token,
        platform: "WEB",
        deviceId: "web"
      }
    );
     

   }

   removeToken(){

   }


}
