<div class="dropdown">
    <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <div class="dropdown-heading">إشعارات</div>
            <div class="dropdown-subheading">
                لديك {{ (todos$ | async).allNotifCount }} إشعارًا جديدًا.
            </div>
        </div>

        <button [matMenuTriggerFor]="settingsMenu" mat-icon-button type="button">
      <mat-icon [icIcon]="icSettings" class="notifications-header-icon"></mat-icon>
    </button>
    </div>

    <div class="dropdown-content">
        <a *ngFor="let notification of (todos$ | async).notifications; trackBy: trackById" (click)="changeStatus(notification.notification,notification.notification.id,notification.seen)" [class.read]="notification.seen" [routerLink]="[]" class="notification"
            fxLayout="row" fxLayoutAlign="start center" matRipple>
            <mat-icon *ngIf="notification.notification.fundTransfer" class="notification-icon" [icIcon]="icReceipt"></mat-icon>
            <mat-icon *ngIf="notification.notification.userRegister" class="notification-icon" [icIcon]="icPerson"></mat-icon>
            <mat-icon *ngIf="notification.notification.fundLoan" class="notification-icon" [icIcon]="icHelp"></mat-icon>
            <mat-icon *ngIf="notification.notification.report" class="notification-icon" [icIcon]="icCheckCircle"></mat-icon>
            <div fxFlex="auto">
                <div class="notification-label">{{notification.notification.content}}</div>
                <div class="notification-description">{{ notification.notification.createdAt | relativeDateTime }}</div>
            </div>

        </a>
    </div>

    <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="center center">
        <button color="primary" (click)="markAllAsSeen()" mat-button type="button">اشر عليها بانها قرات</button>
    </div>
</div>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
    <button mat-menu-item (click)="markAllAsSeen()">
    <mat-icon [icIcon]="icClearAll"></mat-icon>
    <span>اشر عليها بانها قرات</span>
  </button>

    <button mat-menu-item>
    <mat-icon [icIcon]="icClearAll"></mat-icon>
    <span>عرض تاريخ الإخطار الكامل</span>
  </button>
    <!--
  <button mat-menu-item>
    <mat-icon [icIcon]="icNotificationsOff"></mat-icon>
    <span>أوقف التنبيهات</span>
  </button>
    -->

</mat-menu>