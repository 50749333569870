import { NavigationItem } from "../@vex/interfaces/navigation-item.interface";
import icSettings from "@iconify/icons-ic/twotone-settings";
import icPersonAdd from "@iconify/icons-ic/twotone-person-add";
import icHome from "@iconify/icons-ic/twotone-home";
import icPerson from "@iconify/icons-ic/twotone-person";
import icReceipt from "@iconify/icons-ic/twotone-receipt";
import icHelp from "@iconify/icons-ic/help-outline";
import icCheckCircle from "@iconify/icons-ic/twotone-check-circle";
import icTrendingUp from "@iconify/icons-ic/twotone-trending-up";
import icListAlt from "@iconify/icons-ic/twotone-list-alt";
import icChevronRight from "@iconify/icons-ic/twotone-chevron-right";

export const NAVIGATION_MENU: NavigationItem[] = [
  {
    type: "link",
    label: "الرئيسيّة",
    route: "/app/dashboard",
    icon: icHome,
  },
  {
    type: "dropdown",
    label: "إدارة العملاء",
    icon: icPerson,
    children: [
      {
        name: "VIEW-SELLER",
        type: "link",
        label: "بائع",
        route: "/app/users-management/sellers",
      },
      {
        name: "VIEW-BUYER",
        type: "link",
        label: "شاري",
        route: "/app/users-management/buyers/list",
      },
      {
        name: "VIEW-CONTROLLER",
        type: "link",
        label: "مراقب",
        route: "/app/users-management/controllers/list",
      },
      {
        name: "VIEW-BUYER-SELLER",
        type: "link",
        label: "بائع شاري",
        route: "/app/users-management/buyer-sellers/list",
      },
      {
        name: "VIEW-MARKETER",
        type: "link",
        label: "مسوّق",
        route: "/app/users-management/marketers/list",
      },
      {
        name: "VIEW-TRANSPORTER",
        type: "link",
        label: "موصل",
        route: "/app/users-management/transporters",
      },
    ],
  },
  {
    icon: icPersonAdd,

    name: "GET-USER-ACTIVITIES",
    type: "link",
    label: "سجل العملاء",
    route: "/app/users-management/user-activities",
  },
  {
    type: "link",
    label: "ادارة المدراء",
    route: "/app/admin-management",
    icon: icPersonAdd,
    name: "VIEW-ADMIN",
  },
  {
    name: "UPDATE-TRANSPORTER",
    type: "link",
    icon: icSettings,
    label: "إدارة وسائل النقل",
    route: "/app/transport-cars",
  },

  {
    type: "link",
    label: "إدارة المنتجات",
    route: "/app/products-management",
    icon: icReceipt,
    name: "VIEW-PRODUCT",
  },
  {
    type: "link",
    label: "إدارة المعاملات",
    route: "/app/reservation-management",
    icon: icReceipt,
    name: "GET-TRANSACTION-LIST",
  },
  {
    type: "link",
    label: "إدارة التوصيلات",
    route: "/app/transport-management",
    icon: icReceipt,
    name: "GET-TRANSPORT-LIST",
  },
  {
    type: "dropdown",
    label: "إدارة المعاملات المالية",
    icon: icSettings,
    children: [
      {
        type: "link",
        label: " التتبع للمعاملات المالية",
        route: "/app/financial-monitoring",
        icon: icListAlt,
        name: "VIEW-PLATFORM-REVENUE",
      },
      {
        type: "link",
        label: "إدارة الدفع الإلكتروني",
        route: "/app/deposits",
        icon: icReceipt,
        name: "VIEW-FUND-TRANSFER",
      },
      {
        type: "link",
        label: "ادارة التحويلات",
        route: "/app/transactions-management",
        icon: icReceipt,
        name: "VIEW-FUND-TRANSFER",
      },
      {
        type: "link",
        label: "طلبات سحب الرصيد",
        route: "/app/payout-management",
        icon: icReceipt,
        name: "VIEW-FUND-TRANSFER",
      },
      {
        type: "link",
        label: "طلبات التعويضات",
        route: "/app/claims-management",
        icon: icReceipt,
        name: "VIEW-FUND-TRANSFER",
      },
      {
        type: "link",
        label: "إدارة الحسابات البنكية",
        route: "/app/bank-accounts",
        icon: icHelp,
        name: "VIEW-IBAN-CHANGE-DEMAND",
      },
      {
        type: "link",
        label: "مرابيح المنصة",
        route: "/app/profits",
        icon: icListAlt,
        name: "VIEW-PLATFORM-REVENUE",
      },
      {
        type: "link",
        label: "سجل الغرامات",
        route: "/app/penalties",
        icon: icListAlt,
        name: "VIEW-PLATFORM-REVENUE",
      },
    ],
  },

  {
    type: "dropdown",
    label: "إدارة الشكاوي",
    icon: icSettings,
    children: [
      {
        type: "link",
        label: "إدارة شكاوي النقل",
        route: "/app/transport-reports/list",
        icon: icCheckCircle,
        name: "VIEW-COMPLAINT",
      },
      {
        type: "link",
        label: "إدارة شكاوي المعاملات",
        route: "/app/complaints/list",
        icon: icCheckCircle,
        name: "VIEW-COMPLAINT",
      },
      {
        type: "link",
        label: "ادارة التقارير",
        route: "/app/complaints/reports",
        icon: icCheckCircle,
        name: "VIEW-REPORT",
      },
    ],
  },

  {
    type: "link",
    label: "برنامج فرصة",
    route: "/app/subscriptions",
    icon: icTrendingUp,
    name: "VIEW-FORSA-PROGRAM",
  },
  {
    type: "link",
    label: "الاشتراكات",
    route: "/app/subscrption",
    icon: icListAlt,
    name: "VIEW-SUBSCRIPTION",
  },

  {
    name: "VIEW-MESSAGE",
    type: "link",
    label: "دعم العملاء",
    route: "/app/messages-list",
    icon: icListAlt,
  },
  {
    name: "VIEW-MESSAGE",
    type: "link",
    label: "رسائل الزائرين",
    route: "/app/public-message-list",
    icon: icListAlt,
  },
  {
    name: "VIEW-JOIN-REQUEST",
    type: "link",
    label: "طلبات الإنضمام",
    route: "/app/contact-demand-list",
    icon: icListAlt,
  },
  {
    name: "MANAGE-BLOG",
    type: "link",
    label: "إدارة المدونة",
    route: "/app/blog",
    icon: icListAlt,
  },
  {
    name: "MANAGE-BLOG",
    type: "link",
    label: "إدارة الإعلانات",
    route: "/app/ads",
    icon: icListAlt,
  },
  {
    type: "dropdown",
    label: "الاعدادات",
    icon: icSettings,
    children: [
      {
        name: "VIEW-CAR-TYPE",
        type: "link",
        label: "اضافة نوع سيّارة",
        route: "/app/settings/car-types/list",
      },
      {
        name: "VIEW-COOLING-TYPE",
        type: "link",
        label: "اضافة نوع تبريد",
        route: "/app/settings/cooling-types/list",
      },
      {
        name: "VIEW-PRODUCT-UNIT",
        type: "link",
        label: "وحدات القياس",
        route: "/app/settings/measure-units/list",
      },
      {
        name: "VIEW-PRODUCT-PACKAGE",
        type: "link",
        label: "انواع التغليف",
        route: "/app/settings/wrapping-types/list",
      },
      {
        name: "VIEW-CATEGORY",
        type: "link",
        label: "اصناف المنتجات",
        route: "/app/settings/category/list",
      },
      {
        name: "VIEW-QUALITY",
        type: "link",
        label: "جودات المنتجات",
        route: "/app/settings/product-quality",
      },
      {
        name: "VIEW-TYPE",
        type: "link",
        label: "أنواع المنتجات",
        route: "/app/settings/product-type",
      },
      {
        name: "UPDATE-MARKET-TIME",
        type: "link",
        label: "توقيت فتح و غلق السوق",
        route: "/app/settings/trade-time-settings",
      },
      {
        name: "VIEW-POINT-VALUE",
        type: "link",
        label: "نقاط المسوق",
        route: "/app/settings/points",
      },
      {
        name: "VIEW-VAT",
        type: "link",
        label: " الضريبة على القيمة المضافة",
        route: "/app/settings/vat",
      },
      {
        name: "VIEW-PROFIT",
        type: "link",
        label: " نسبة المرابيح بالمنصة",
        route: "/app/settings/profit",
      },
      {
        name: "CREATE-SUBJECT",
        type: "link",
        label: "إضافة موضوع",
        route: "/app/settings/topic/list",
      },
      {
        name: "CREATE-REGION",
        type: "link",
        label: "إدارة المناطق والمدن",
        route: "/app/settings/region/list",
      },
      {
        name: "VIEW-BANK-ACCOUNT",
        type: "link",
        label: "الحساب المصرفي للمنصة",
        route: "/app/settings/bank-account",
      },
      {
        name: "VIEW-SETTING",
        type: "link",
        label: "إعدادات عامة",
        route: "/app/settings/setting",
      },
    ],
  },
  {
    type: "link",
    label: "شروط و احكام",
    route: "/app/terms-and-conditions",
    icon: icChevronRight,
    name: "VIEW-RULES",
  },
];
