import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PopoverService} from '../../../components/popover/popover.service';
import {ToolbarNotificationsDropdownComponent} from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import icNotificationsActive from '@iconify/icons-ic/twotone-notifications-active';
import { DataService, NotifInfo } from 'src/app/shared/services/data.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'vex-toolbar-notifications',
    templateUrl: './toolbar-notifications.component.html',
    styleUrls: ['./toolbar-notifications.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarNotificationsComponent implements OnInit {
    todos$: Observable<NotifInfo>;

    @ViewChild('originRef', {static: true, read: ElementRef}) originRef: ElementRef;

    dropdownOpen: boolean;
    icNotificationsActive = icNotificationsActive;

    constructor(private popover: PopoverService,
                private cd: ChangeDetectorRef,
                private dataService: DataService) {
    }

    ngOnInit() {
        this.todos$ = this.dataService.todos$;
    }

    showPopover() {
        this.dropdownOpen = true;
        this.cd.markForCheck();

        const popoverRef = this.popover.open({
            content: ToolbarNotificationsDropdownComponent,
            origin: this.originRef,
            offsetY: 12,
            position: [
                {
                    originX: 'center',
                    originY: 'top',
                    overlayX: 'center',
                    overlayY: 'bottom'
                },
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'top',
                },
            ]
        });

        popoverRef.afterClosed$.subscribe(() => {
            this.dropdownOpen = false;
            this.cd.markForCheck();
        });
    }
}
