import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENDPOINTS } from 'src/core/endpoints';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationManagementService {

  constructor(
    private http: HttpClient
  ) { }


  getNotifications = (limit): Observable<any> => {
    return this.http.get(`${environment.baseURL}${ENDPOINTS.NOTIFICATION.ADMIN}?limit=${limit}`);
  }
  updateNotification = (id,status): Observable<any> => {
    return this.http.patch(`${environment.baseURL}${ENDPOINTS.NOTIFICATION.UPDATE_STATUS}${id}`,
    {
      seen: status
    });
  }
  updateAdminNotifications = (status,limit): Observable<any> => {
    return this.http.patch(`${environment.baseURL}${ENDPOINTS.NOTIFICATION.UPDATE_ALL_STATUS}?limit=${limit}`,
    {
      seen: status
    });
  }

}
