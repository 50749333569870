<div class="toolbar w-full px-gutter" fxLayout="row" fxLayoutAlign="start center" vexContainer>
    <button (click)="openSidenav()" [fxHide]="!mobileQuery" mat-icon-button type="button">
        <mat-icon [icIcon]="icMenu"></mat-icon>
    </button>


    <span fxFlex></span>

    <div class="-mx-1 flex items-center">
        <div class="px-1">
            <vex-toolbar-notifications></vex-toolbar-notifications>
        </div>



        <!--    <div class="px-1">-->
        <!--      <button (click)="openSearch()" mat-icon-button type="button">-->
        <!--        <mat-icon [icIcon]="icSearch"></mat-icon>-->
        <!--      </button>-->
        <!--    </div>-->

        <!--    <div class="px-1">-->
        <!--      <vex-toolbar-notifications></vex-toolbar-notifications>-->
        <!--    </div>-->

        <!--    <div class="px-1">-->
        <!--      <button (click)="openQuickpanel()" mat-icon-button type="button">-->
        <!--        <mat-icon [icIcon]="icPerson"></mat-icon>-->
        <!--      </button>-->
        <!--    </div>-->

        <div class="px-1">
            <vex-toolbar-user></vex-toolbar-user>
        </div>

        <!--    <mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">-->
        <!--      <button mat-menu-item>-->
        <!--        <mat-icon [icIcon]="emojioneUS"></mat-icon>-->
        <!--        <span>English</span>-->
        <!--      </button>-->

        <!--      <button mat-menu-item>-->
        <!--        <mat-icon [icIcon]="emojioneDE"></mat-icon>-->
        <!--        <span>German</span>-->
        <!--      </button>-->
        <!--    </mat-menu>-->

        <!--
 <div class="px-1 mx-6">
            <mat-icon class="screen" *ngIf="!isFullScreen" (click)="openFullscreen()">fullscreen</mat-icon>
            <mat-icon class="screen" *ngIf="isFullScreen" (click)="closeFullscreen()">fullscreen_exit</mat-icon>
        </div>
        -->



    </div>
</div>


<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
    [fxHide]="mobileQuery"></vex-navigation>