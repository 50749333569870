import { Component, OnInit } from '@angular/core';
import { Notification } from '../interfaces/notification.interface';
import { trackById } from '../../../../utils/track-by';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import icClearAll from '@iconify/icons-ic/twotone-clear-all';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icPerson from '@iconify/icons-ic/twotone-person';
import { NotificationManagementService } from '../services/notification-management.service';
import { DataService, NotifInfo } from 'src/app/shared/services/data.service';
import { Observable } from 'rxjs';
import icHelp from '@iconify/icons-ic/help-outline';
import { Router } from '@angular/router';
import { EventEmitterService } from 'src/app/shared/services/event-emitter.service';

@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent implements OnInit {
  unSeenCount = 0;
  todos$: Observable<NotifInfo>;
  icReceipt = icReceipt
  icPerson = icPerson
  icCheckCircle = icCheckCircle
  icHelp = icHelp
  notifications: Notification[] = [/*
    {
      id: '1',
      label: 'New Order Received',
      icon: icShoppingBasket,
      colorClass: 'text-primary',
      datetime: DateTime.local().minus({ hour: 1 })
    },
    {
      id: '2',
      label: 'New customer has registered',
      icon: icAccountCircle,
      colorClass: 'text-orange',
      datetime: DateTime.local().minus({ hour: 2 })
    },
    {
      id: '3',
      label: 'Campaign statistics are available',
      icon: icInsertChart,
      colorClass: 'text-purple',
      datetime: DateTime.local().minus({ hour: 5 })
    },
    {
      id: '4',
      label: 'Project has been approved',
      icon: icCheckCircle,
      colorClass: 'text-green',
      datetime: DateTime.local().minus({ hour: 9 })
    },
    {
      id: '5',
      label: 'Client reports are available',
      icon: icDescription,
      colorClass: 'text-primary',
      datetime: DateTime.local().minus({ hour: 30 })
    },
    {
      id: '6',
      label: 'New review received',
      icon: icFeedback,
      colorClass: 'text-orange',
      datetime: DateTime.local().minus({ hour: 40 }),
      read: true
    },
    {
      id: '7',
      label: '22 verified registrations',
      icon: icVerifiedUser,
      colorClass: 'text-green',
      datetime: DateTime.local().minus({ hour: 60 })
    },
    {
      id: '8',
      label: 'New files available',
      icon: icFileCopy,
      colorClass: 'text-amber',
      datetime: DateTime.local().minus({ hour: 90 })
    }*/
  ];

  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icClearAll = icClearAll;
  icNotificationsOff = icNotificationsOff;
  limit = 50;
  trackById = trackById;

  constructor(private notifService: NotificationManagementService,
    private dataService: DataService, private router: Router, private eventEmitterService: EventEmitterService) { }

  markAsSeen(id: string) {
    this.notifService.updateNotification(id, true).subscribe(
      res => {
        console.log(res)
        this.getNotifications(this.limit)
      },
      err => {
        console.log(err)
      }
    )
  }
  markAllAsSeen() {
    this.notifService.updateAdminNotifications(true, this.limit).subscribe(
      res => {
        this.getNotifications(this.limit)
      }
    )
  }
  markAsUnSeen(id: string) {
    this.notifService.updateNotification(id, false).subscribe(
      res => {
        console.log(res)
        this.getNotifications(this.limit)
      },
      err => {
        console.log(err)
      }
    )
  }
  changeStatus(notification, id, state) {
    if (state == true) {
      this.markAsUnSeen(id)
    } else {
      this.markAsSeen(id)
      this.router.navigate(['app/dashboard']);
      if (notification.fundTransfer) {
        this.router.navigate(['app/transactions-management']);
        this.eventEmitterService.onFundTransferComponentNotificationClick();
      }

      if (notification.receiveMoney) {
        {
          this.router.navigate(['app/payout-management']);
          this.eventEmitterService.onPayoutComponentNotificationClick();
        }
      }
      if (notification.fundTransfer) {
        {
          this.router.navigate(['app/transactions-management']);
          this.eventEmitterService.onFundTransferComponentNotificationClick();
        }
      }
      if (notification.ibanChangeDemand) {
        this.router.navigate(['app/bank-accounts']);
        this.eventEmitterService.onBankAccountComponentNotificationClick();
      }
      if (notification?.report?.status == 'DONE' || notification?.report?.status == 'APPROVED_BY_ADMIN' || notification?.report?.status == 'REFUSED_BY_ADMIN') {
        this.router.navigate(['app/complaints/reports']);
        this.eventEmitterService.onReportComponentNotificationClick();
      }
      if (notification?.report?.status == 'PENDING' || notification?.report?.status == 'CONFIRMED' || notification?.report?.status == 'INVESTIGATING' || notification?.report?.status == 'CLOSED') {
        this.router.navigate(['app/complaints/list']);
        this.eventEmitterService.onComplaintsComponentNotificationClick();
      }
      if (notification.message) {
        this.router.navigate(['app/messages-list']);
        this.eventEmitterService.onMessageComponentNotificationClick();
      }
      if (notification.userRegister && notification.userRegister.type == 'BUYER_SELLER') {
        this.router.navigate(['app/users-management/buyer-sellers/list']);
        this.eventEmitterService.onBuyerSellerComponentNotificationClick();
      }
      if (notification.userRegister && notification.userRegister.type == 'SELLER') {
        this.router.navigate(['app/users-management/sellers']);
        this.eventEmitterService.onSellerComponentNotificationClick();
      }
      if (notification.userRegister && notification.userRegister.type == 'BUYER') {
        this.router.navigate(['app/users-management/buyers/list']);
        this.eventEmitterService.onBuyerComponentNotificationClick();
      }
      if (notification.userRegister && notification.userRegister.type == 'TRANSPORTER') {
        this.router.navigate(['app/users-management/transporters/list']);
        this.eventEmitterService.onTransporterComponentNotificationClick();
      }
      if (notification.userRegister && notification.userRegister.type == 'TRANSPORTER_AGENT') {
        this.router.navigate(['app/users-management/transporters/list']);
        this.eventEmitterService.onTransporterComponentNotificationClick();
      }
      if (notification.userRegister && notification.userRegister.type == 'TRANSPORTER_COMPANY') {
        this.router.navigate(['app/users-management/transporters/list']);
        this.eventEmitterService.onTransporterComponentNotificationClick();
      }
      if (notification.userRegister && notification.userRegister.type == 'CONTROLLER')
        this.router.navigate(['app/users-management/controllers/list']);
      if (notification.userRegister && notification.userRegister.type == 'MARKETER')
        this.router.navigate(['app/users-management/marketers/list']);
      if (notification.product) {
        this.router.navigate(['app/products-management']);
        this.eventEmitterService.onProductComponentNotificationClick();
      }
      if (notification.reportTransporter) {
        this.router.navigate(['app/transport-reports/list']);
        this.eventEmitterService.onReportTransporterComponentNotificationClick();
      }
      if (notification.deposit) {
        this.router.navigate(['app/deposits']);
      }

    }

  }

  getNotifications(limit) {
    this.notifService.getNotifications(limit).subscribe(
      res => {
        this.dataService.update(res.data)
      }
    )
  }
  timeDiff(dateFuture, dateNow) {
    return Math.abs(dateFuture - dateNow) / 1000;

  }

  ngOnInit() {
    this.todos$ = this.dataService.todos$;
    //this.getNotifications(this.limit)
  }

}
