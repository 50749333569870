import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import { NavigationService } from 'src/@vex/services/navigation.service';
import {AuthenticationService} from '../../authentication/services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class PrivateGuard implements CanActivate {
    constructor(private router: Router, private navService: NavigationService) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const result = !!localStorage.getItem('UT');
        if (!result) {
            this.router.navigate(['/']);
        }
        else {
            if (!this.navService.permissions || !this.navService.permissions?.length) {
                const permissions = JSON.parse(localStorage.getItem('UD')).managementPacks;
                this.navService.setPacksAndPermissions(permissions);
                console.log(permissions);
                
            }
        }
        return result;
    }

}
