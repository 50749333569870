<div
  class="navigation"
  fxLayout="row"
  fxLayoutAlign="start center"
  vexContainer
>
  <ng-container *ngFor="let item of items">
    <vex-navigation-item *ngIf="item.name" [item]="item"></vex-navigation-item>
  </ng-container>
</div>
